import { getTrees, getPuaSteps, getTreesCount, getWorkGallery as workGallery, saveTreeSeederContact } from '@/services/tree'

export const setTreesCount = async (context) => {
  if(!context.getters.isTreesCountSet){
    const response = await getTreesCount();
    context.commit('SET_TREES_COUNT', response);
  }
  return;
}

export const getAllTrees =  async() => {
  return await getTrees();
}

export const getGalleryWork = async () => {
  return workGallery();
}

export const getSteps = async () =>{
  return getPuaSteps();
}

export const saveTreeSeederRequest = async (context, data) => {
  return await saveTreeSeederContact(data);
}