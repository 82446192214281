<template>
  <div>
    <!-- Condicional para mostrar el componente solo si no es un dispositivo Apple -->
    <carousel
      :autoplay="!noAutoplay"
      :autoplayTimeout="autoplayTimeout"
      :loop="!noLoop"
      :nav="showNav"
      :dots="showDots"
      :margin="getMargin"
      :autoWidth="!noAutoWidth"
      v-if="!UserSystem.appleUser()"
    >
      <slot />
    </carousel>
  </div>
</template>

<script>
import Carousel from 'vue-owl-carousel';
import UserSystem from '@/domain/UserSystem.js'; 

export default {
  name: 'SmallCarrousel',

  props: {
    margin: {
      type: Number,
      default: 20
    },

    noMarginResponsive: Boolean,

    autoplayTimeout: {
      type: Number,
      defaul: 4000,
    },

    noAutoplay: Boolean,
    noLoop: Boolean,
    showNav: Boolean,
    showDots: Boolean,
    noAutoWidth: Boolean,
  },

  data() {
      return {
        UserSystem: UserSystem,
      };
    },

  components: {
    Carousel
  },

  methods: {
    getMarginSize(margin, sizeIndex){
      return margin + (margin * sizeIndex);
    }
  },

  computed: {
    getMargin(){
      if (this.noMarginResponsive){
        return this.margin;
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return this.margin;
          case 'sm': return this.getMarginSize(this.margin, 1);
          case 'md': return this.getMarginSize(this.margin, 2);
          case 'lg': return this.getMarginSize(this.margin, 4);
          default: return this.getMarginSize(this.margin, 4);
        }
      }
    }
  }
}
</script>
