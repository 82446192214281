export const SET_TREES = (state, trees) => {
   state.trees = trees;
}

export const SET_PAY_METHOD = (state, card) => {
   state.payMethod = card;
}

export const SET_SUBSCRIPTION = (state, subscription) => {
   state.subscription = subscription;
}