import api from '@/api';

export const getPaymentCard = async () => {
    try {
        const response = await api.get('/userPaymentData');
        const cards = response.data.data;
    
        return cards[0] || null;
    } catch (error) {
        throw 'Hubo un error al recuperar la información de pago'
    }
}

export const saveCard = async (card) => {
    const payload = {
        user_id: 1,
        legal_name: `${card.name} ${card.lastName}`,
        address: 'Av. Madero',
        sip_code: '47565',
        city: 'Matanzas',
        country: 'required',
        'region': 'Mexico',
        ...card
    }

    const response = await api.post('/userPaymentData', payload);

    return response.data.data;
}