<template>
  <div :style="cssVars" class="pua-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppInput',

  props: {
    color: {
      type: String,
      default: 'white'
    },

    width: {
      type: String,
      default: 'auto'
    },

    height: {
      type: String,
      default: 'auto'
    }
  },

  computed: {
    inputValue: {
      set(newValue) {
        this.$emit('input', newValue);
      },

      get(){
        return this.value;
      }
    },

    cssVars(){
      return {
        '--color': this.color,
        '--width': this.width,
        '--height': this.height,
      }
    }
  }
}
</script>

<style scoped>
.pua-card{
  background: var(--color);
  padding: 15px 15px 15px 15px;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: var(--height);
  max-width: var(--width);
  transition: ease-in-out 0.3s;
}

.pua-card:hover{
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.35);
  transition: ease-in-out 0.3s;
}
</style>