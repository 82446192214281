import { newQuestion, similarQuestions } from '@/services/question';

export const newQuestionResponse = async (context, question) => {
  const response = await newQuestion(question);
  context.commit('UPDATE_CONVERSATION', response);
  
  return response;
}

export const findSimilarQuestions = async (context, question) => {
  return await similarQuestions(question);
}
