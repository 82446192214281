export const SET_SUBSCRIPTIONS = (state, data) => {
   state.subscriptions = data;
}

export const SET_SELECTED_LEVEL = (state, level) => {
   state.selectedLevel = level;
}

export const SET_SUBSCRIPTION_SUCCESS = (state, status) => {
   state.subscriptionSuccess = status;
}

