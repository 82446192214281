import api from '@/api';

export const saveTreeSeederContact = async(data) => {
  const response = await api.post('form', {status: 1, form_type: 2, ...data})
  return response.data;
}   

export const getTreesCount = async() => {
    const trees = await getTrees();

    return {
        count: trees.length,
        effectiveness: 93
    }
}

export const getTrees = async() => {
    const response = await api.get('trees/all');
    return response.data.data;
}

export const getTreeInformation = async(id) => {
  const response = await api.get(`trees/all/${id}`);
  return response.data.data;
}

export const getUserTrees = async(userId) => {
  const response = await api.get(`/trees/user-tree/getUserTrees/${userId}`);

  return response.data.data;
} 

export const getWorkGallery = () => {
    return [
        'https://firebasestorage.googleapis.com/v0/b/plantemosunarbol-b77dc.appspot.com/o/galleryWork%2Fwork1.2.webp?alt=media&token=172f6b19-e56e-497d-85b0-ba07e53ff8ea',
        'https://firebasestorage.googleapis.com/v0/b/plantemosunarbol-b77dc.appspot.com/o/galleryWork%2Fwork2.2.webp?alt=media&token=9b50ca08-1147-4cf5-b5f0-8513cafd0774',
        'https://firebasestorage.googleapis.com/v0/b/plantemosunarbol-b77dc.appspot.com/o/galleryWork%2Fwork3.2.webp?alt=media&token=7ee732db-69b4-4b4e-9d31-f6515dcdf197',
        'https://firebasestorage.googleapis.com/v0/b/plantemosunarbol-b77dc.appspot.com/o/galleryWork%2Fwork4.2.webp?alt=media&token=29e2b6ce-37d1-4f50-8956-18667ec792d6',
        'https://firebasestorage.googleapis.com/v0/b/plantemosunarbol-b77dc.appspot.com/o/galleryWork%2Fwork5.2.webp?alt=media&token=a7c499ae-53fd-45b9-8e26-ac361e910b1e',
        'https://firebasestorage.googleapis.com/v0/b/plantemosunarbol-b77dc.appspot.com/o/galleryWork%2Fwork6.2.webp?alt=media&token=f3d26d03-2889-4b2a-be0c-c219290eef98',
    ]
}

export const getPuaSteps = () => {
    return [
        { title:'Suscripciones', description: 'Mantener una suscripción activa, es vital para PUA, pues las suscripciones son nuestra primera fuente de ingresos para plantar arboles.'},
        { title:'Campañas', description: 'Cuando se abre una campaña, se analizan los usuarios que han aportado lo suficiente para que se plante un árbol.'},
        { title:'Plantaciones', description: 'Si fuiste uno de los usuarios que entraron en la campaña, cuando se plante tu árbol se te notificará y obtendras información de el para darle seguimiento, asi como la ubicacion por si gustas visitarlo.'},
    ]
}