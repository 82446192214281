<template>
    <div>
        <router-link class="pua-text animated-link" to="/login" v-if="!isUserLoggedIn">Iniciar Sesión</router-link>
        <div v-else>
            <v-menu
                min-width="300px"
                content-class="elevation-2"
                :elevation="0"
                bottom
                right
                transition="scroll-x-reverse-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon color="primary-light">mdi-account</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <div class="text-center py-2 font-weight-bold">{{user.name}}</div>
                    <v-divider />
                    <v-list-item-group color="primary">
                        <list-item title="Mi perfil" icon="mdi-account-arrow-right" url="/my-profile" />
                        <list-item title="Salir" icon="mdi-logout" url="/logout" />
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListItem from './ListItem.vue';

export default {
  components: { ListItem },
    name: "AuthControl",

    props: {
        name: {
            type: String,
            default: 'main', //big || small
        },
    },

    computed: {
        ...mapGetters('Auth', ['isUserLoggedIn', 'user']),
    }
}
</script>