import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './auth'
import Sponsor from './sponsor'
import Tree from './tree'
import User from './user'
import Subscription from './subscription'
import Ai from './ai'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Sponsor,
    Tree,
    User,
    Subscription,
    Ai
  }
})
