<template>
  <div :style="cssVars">
    <button class="button-box" :type="type" @click="$emit('click')" :disabled="loading">
      <div v-if="loading && !disableLoading">
        <v-progress-circular
          :size="25"
          color="white"
          indeterminate
        ></v-progress-circular>
      </div>
      <slot v-else />
    </button>
  </div>
</template>

<script>
export default {
  name: 'AppInput',

  props: {
    type: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: '#00701A'
    },

    fontColor: {
      type: String,
      default: 'white',
    },

    width: {
      type: String,
      default: '100%'
    },

    loading: Boolean,
    disableLoading: Boolean
  },

  computed: {
    cssVars(){
      return {
        '--color': this.color,
        '--font-color': this.fontColor,
        '--width': this.width,
      }
    }
  }
}
</script>

<style scoped>
  .button-box {
    width: var(--width);
    color: var(--font-color);
    padding: 0px 20px 0px 20px;
    height: 35px;
    border-radius: 10px;
    background: var(--color);
    transition: ease-in-out 0.5s;
  }

  .button-box:hover {
    background: var(--color);
    opacity: 0.9;
    transition: ease-in-out 0.3s;
  }
</style>