export const subscriptions = (state) => {
    return state.subscriptions;
}

export const hasSelectedLevel = (state) => {
    return state.selectedLevel !== null;
}

export const selectedLevelId = (state) => {
    return state.selectedLevel?.id || -1
}

export const selectedLevel = (state) => {
    return state.selectedLevel;
}

export const subscriptionSucces = (state) => {
    return state.subscriptionSuccess;
}
