import { getSubscriptions, createSubscription } from '@/services/subscription'
import { saveCard } from '@/services/payment'

export const setSubscriptions = async (context) => {
  if(context.state.subscriptions.length <= 0){
    const response = await getSubscriptions();
    context.commit('SET_SUBSCRIPTIONS', response);
  }

  return
}

export const setSubscriptionSelection = async(context, level) => {
  context.commit('SET_SELECTED_LEVEL', level);
  return;
}

export const storeCard = async(context, card) =>{
  try {
    const cardSaved = await saveCard(card);
    return cardSaved;
  } catch ( {response} ) {
    throw { ...response, errorInCard: true}
  }
}

export const createUserSubscription = async(context) => {
  try {
    const subscription = await createSubscription(context.getters['selectedLevelId']);
    context.commit('SET_SUBSCRIPTION_SUCCESS', true);
    return subscription
  } catch ( error ) {
    throw { ...error, errorInSubscription: true}
  }
}