export const isUserLoggedIn = (state) => {
    return state.token !== null ? true : false;
};

export const user = (state) => {
    return {
        name: state.name,
        id: state.userId,
        email: state.email,
    };
};
