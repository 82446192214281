<template>
    <div>
        <ICountUp :delay="delay" :endVal="count" :options="options" @ready="onReady" />
    </div>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {

    props: {
        delay: {
            type: Number,
            default: 1000,
        },
        endVal: {
            type: Number,
            required: true,
        },
    },

    components: {
        ICountUp,
    },
    
    data() {
        return {
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ",",
                decimal: ".",
                prefix: "",
                suffix: "",
            },
            count: 0,
        };
    },

    created() {
        this.count = this.endVal;
    },
    
    methods: {
        onReady: function (instance) {
            try {
                const that = this;
                instance.update(that.count);
            } catch (error) {
                console.log(error);
            }
        },
    },
    
};
</script>