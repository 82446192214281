import { priceString } from '@/helpers/priceFormat';

class Subscription {
    constructor(data){
        this.id = data.id;
        this.urlImage = data.url_image;
        this.name = data.name;
        this.active = data.active;
        this.every = data.text_duration;
        this.cost = priceString(data.cost);
        this.costNumber = data.cost;
        this.tax = data.tax;
        this.options = data.has_options_detail;
    }

    getAsObject = () => {
        return {
            id: this.id,
            urlImage: this.urlImage,
            name:this.name,
            active: this.active,
            every: this.every,
            cost: this.cost,
            costNumber: this.costNumber,
            tax: this.tax,
            options: this.options,
        }
    }
}

export default Subscription;