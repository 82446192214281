import api from "@/api";

export const newQuestion = async (question) => {
    const response = await api.post('question', { question });
    return response.data.data;
}

export const similarQuestions = async (question) => {
    const response = await api.post('get-question-suggest', {question})
    const questions = response.data.data.data;
    
    return questions.filter(q => q !== question)
}