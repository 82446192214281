import api from '@/api';
import Subscription from '@/domain/Subscription';

export const createSubscription = async (levelId) => {
    const response = await api.post('/userSubscription', { subscription_level_id: levelId, payment_method_id: '1'});
    const userSubscription = response.data.data;

    return {
        id: userSubscription.id,
        active: userSubscription.renovate == 1 ? true : false,
        expirationDate: userSubscription.expiration_date,
        levelDetail: new Subscription(userSubscription.levelDetail).getAsObject(),
    }
}

export const getUserSubscription = async () => {
    try {
        const response = await api.get('/userSubscription');

        const subscription = response.data.data.actual_subscription;
        if(subscription){
            return {
                id: subscription.id,
                active: subscription.renovate == 1 ? true : false,
                expirationDate: subscription.expiration_date,
                levelDetail: new Subscription(subscription.levelDetail).getAsObject(),
            }
        }
    
        return subscription || null;
    } catch (error) {
        console.log(error);
        throw 'Hubo un error al recuperar tu suscripción'
    }

}

export const getSubscriptions = async () => {
    const response = await api.get('/subscriptions');
    const subscriptions = response.data.data;
    
    return subscriptions.map(subscription => {
        return new Subscription(subscription).getAsObject();
    })
}