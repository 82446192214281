import axios from 'axios';
import { getUserToken } from '@/services/localStorage';

const token = getUserToken();
const baseURL = process.env.VUE_APP_URL_API


const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${ token }`
    }
});

api.interceptors.request.use(function (config) {
    const refreshToken = getUserToken();
    
    if(refreshToken){
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${ refreshToken }`
        }
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    //go to login if 401
    return Promise.reject(error);
});

export default api;