import api from '@/api';

export const register = async(credentials) => {
    const response = await api.post('auth/register', credentials);
    return response.data;
}

export const login = async(credentials) => {
    const response = await api.post('auth/login', credentials);
    return response.data;
}

export const logout = async() => {
    const response = await api.post('/logout');
    return response.data;
}

export const getUserSession = async (token) => {
    const response = await api.get('/user-profile');
    const user = {...response.data.data, token};
    return user;
}

export const passwordRecovery = async (credentials) => {
    const response = await api.post('/auth/forgot', credentials);
    return response;
}

export const passwordReset = async (credentials) => {
    const response = await api.post('/auth/reset', credentials);
    return response.data;
}
