<template>
<v-footer
  padless
>
    <v-card
      width="100%"
      flat
      tile
      class="primary lighten-1 white--text"
    >
      <app-container class="py-8">
        <v-row no-gutters justify="space-between" class="gap-2">
          <v-col cols="5" sm="2">
            <app-logo />
          </v-col>
          <v-col cols="5" sm="2">
            <h2 class="pua-text">¿Por qué PUA?</h2>
            <ul class="pua-text-sm pua-list">
              <li><router-link to="/sobre-nosotros">Necesidad</router-link></li>
              <li><router-link to="/results">Resultados</router-link></li>
            </ul>
          </v-col>
          <v-col cols="5" sm="2">
            <h2 class="pua-text">Precios</h2>
            <ul class="pua-text-sm pua-list">
              <li><router-link to="/subscriptions">Planes</router-link></li>
              <li><router-link to="/soy-organizacion">Para organizaciones</router-link></li>
            </ul>
          </v-col>
          <v-col cols="5" sm="2">
            <h2 class="pua-text">¿Quienes somos?</h2>
            <ul class="pua-text-sm pua-list">
              <li><router-link to="/sobre-nosotros">Conocenos</router-link></li>
            </ul>
          </v-col>
          <v-col cols="5" sm="2">
            <h2 class="pua-text">Redes Sociales</h2>
            <ul class="pua-text-sm pua-list">
              <li><a href="https://www.facebook.com/profile.php?id=100094370950124&mibextid=LQQJ4d" target="_blank">Facebook</a></li>
              <li><a href="https://www.tiktok.com/@plantemosunarbol?_t=8gemm0kn9pm&_r=1" target="_blank">Tiktok</a></li>
            </ul>
          </v-col>
        </v-row>
      </app-container>

        <v-divider color="white"></v-divider>

        <v-card-text class="white--text text-center" >
          {{ new Date().getFullYear() }} © <span>Plantemos un árbol</span>
          <br>
          Zapopan Jalisco, México
        </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  import AppLogo from '@/components/AppLogo';
  import AppContainer from '@/components/AppContainer.vue';

  export default {
    name: 'AppFooter',

    components: {
      AppLogo,
      AppContainer
    },

    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
  }
</script>