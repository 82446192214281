import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/results',
    name: 'results',
    component: () => import(/* webpackChunkName: "about" */ '../views/Results.vue')
  },
  {
    path: '/details/tree/:id',
    name: 'abouttree',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutTreeView.vue')
  },
  {
    path: '/sobre-nosotros',
    name: 'aboutus',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { guest: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { guest: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    meta: { guest: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { requiresAuth: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue')
  },
  {
    path: '/my-profile',
    name: 'myProfile',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyProfile.vue')
  },
  {
    path: '/my-trees/:userId',
    name: 'userTreeMap',
    meta: { },
    component: () => import(/* webpackChunkName: "about" */ '../views/UserTreeMap.vue')
  },
  {
    path: '/user/subscription',
    name: 'userSubscription',
    meta: { requiresAuth: true },
    component: () => import ('../views/UserSubscription.vue')
  },
  {
    path: '/subscription/payment',
    name: 'newPayMethod',
    meta: { requiresAuth: true },
    component: () => import ('../views/SubscriptionPayment.vue')
  },
  {
    path: '/subscription/success',
    name: 'success',
    meta: { requiresAuth: true },
    component: () => import ('../views/SubscriptionSuccess.vue')
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    meta: {},
    component: () => import ('../views/SubscriptionView.vue')
  },
  {
    path: '/soy-plantador',
    name: 'soy-plantador',
    meta: {},
    component: () => import ('../views/ContactSeeder.vue')
  },
  {
    path: '/soy-organizacion',
    name: 'soy-organizacion',
    meta: {},
    component: () => import ('../views/ContactCompany.vue')
  },
  {
    path: '/pua-ai',
    name: 'questions',
    meta: {},
    component: () => import ('../views/Questions.vue')
  },
  
  {
    path: '/*',
    name: 'noFound',
    meta: {},
    component: () => import ('../views/NotFound.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  //detect token change manually left
  await store.dispatch('Auth/verifyUserSession');
  
  if(!to.meta.guest && !to.meta.requiresAuth){
    next();
  } else{

    await store.dispatch('Auth/validateSession');
    const isAuthenticated = store.getters['Auth/isUserLoggedIn'];

    if(to.meta.requiresAuth && !isAuthenticated)
      next({ name: 'login' })
    else if(to.meta.guest && isAuthenticated)
      next({name: 'home'});
    else
      next();
  }
})

export default router
