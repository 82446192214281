import api from '@/api';
import { getImageUrl } from '@/helpers/fileUrl';

export const saveSponsorContact = async(data) => {
    const response = await api.post('form', {status: 1, form_type: 1, ...data})
    return response.data;
}   

export const getSponsors = async() => {
    try {
        const sponsors = (await api.get('/sponsors-list')).data.data;

        const sponsorsMapped = sponsors.map(({ name, website, logo }) => {
            return {
                name,
                referrer: website,
                image: getImageUrl(logo)
            }
        });

        if(sponsorsMapped.length >= 4){
            return sponsorsMapped
        }

        return [...sponsorsMapped, ...sponsorsMapped, ...sponsorsMapped];
    } catch (error) {
        return []
    }

}