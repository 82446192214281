import Crypter from '@/domain/crypter';

export const setUserToken = (token) => {
    localStorage.setItem('userId', token);
}

export const getUserToken = () => {
    const token = localStorage.getItem('userId');
    return token === '' || undefined ?  null : token;
}

export const setPasswordRecoveryEmail = (email) => {
    const emailEncripted = new Crypter().encryp(email);
    localStorage.setItem('recovery-email', emailEncripted);
}

export const getPasswordRecoveryEmail = () => {
    const emailEncripted = localStorage.getItem('recovery-email');
    const emailDecripted = new Crypter().decrypt(emailEncripted);

    return emailDecripted === '' ?  null : emailDecripted;
}

export const updateQuestions = () => { //updates the number of questions that a user asks per day
    const today = new Date().toJSON().slice(0, 10);
    const limit = 5;

    const saveItem = (date, numberOfQuestions) => {
        localStorage.setItem('questions', JSON.stringify({date, numberOfQuestions}))
    }

    const questions = localStorage.getItem('questions');
    if(questions) {
        const { date, numberOfQuestions } = JSON.parse(questions);
        if(today == date){
            if(numberOfQuestions < limit){
                saveItem(today, numberOfQuestions + 1)
                return true;
            } else {
                return false;
            }
        } else {
            saveItem(today, 1)
            return true;
        }
    } else {
        saveItem(today, 1)
        return true;
    }

}