import { getUserTrees } from '@/services/tree';
import { getPaymentCard } from '@/services/payment';
import { getUserSubscription } from '@/services/subscription';
import { updateQuestions } from '@/services/localStorage';

import Crypter from '@/domain/crypter';

export const setUserData = async (context) => {
  try {
    const card = await getPaymentCard();
    context.commit('SET_PAY_METHOD', card);
  } catch (error) {
    console.log('error', error);
  }

  try {
    const subscription = await getUserSubscription();
    context.commit('SET_SUBSCRIPTION', subscription);
  } catch (error) {
    console.log('error subscription', error);
  }

  return;
}

export const setCard = async (context, card) => { //we set the cards when are created from subscription module
  context.commit('SET_PAY_METHOD', card);
  return
}

export const setSubscription = async (context, subscription) => { //we set the subscription when are created from subscription module
  context.commit('SET_SUBSCRIPTION', subscription)
}

export const unsetUserData = (context) => {
  context.commit('SET_SUBSCRIPTION', null);
  context.commit('SET_PAY_METHOD', null);
  context.commit('SET_TREES', []);

  return;
}

export const setTrees = async (context) => {
  if(!context.getters.hasTrees){
    const response = await getUserTrees(context.getters.user.id);
    context.commit('SET_TREES', response);
  }
  return;
}

export const getUserTreesById = async(context, userId) => {
  return await getUserTrees(userId);
}

export const getUserIdEncrypted = (context) => { 
  const user = context.getters.user;
  return new Crypter().encryp(user.id.toString());
}

export const decrypUserId = (context, id) => { 
  const decryptedID = new Crypter().decrypt(id);
  return parseInt(decryptedID);
}

export const canAsk = async (context) => {
  if(context.getters.isUserLoggedIn)
    return true;
  else 
    return updateQuestions();
}