import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
          light: {
            primary: "000000", 
            secondary: "#00701A",
            secondaryLight: "#43a047",
            accent: "#f94f46",
            error: "#D8000C",
            errorLight: "#f3bebe",
          },
      },
    }
});
