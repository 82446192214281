<template>
    <div :class="[padding, container]">
        <slot />
    </div>
</template>

<script>
export default {
    name: "AppContainer",

    props: {
        name: {
            type: String,
            default: 'main', //big || small
        },

        addPadding: Boolean,
    },

    computed: {
        container() {
            return `${this.name}-container`;
        },

        padding() {
            return this.addPadding ? 'page-padding' : '';
        }
      
    }
}
</script>