<template>
  <v-navigation-drawer
    v-model="showDrawer"
    app
    color="white"
    bottom
    clipped
    mobile-breakpoint="960"
  >
    <v-row
      no-gutters
    > 
      <v-list class="grow" dense nav>
        <v-list-item-group color="secondary">
          <list-item title="Resultados" url="/"/>
          <list-item title="Te ayudo a plantar" url="/pua-ai" class="my-2"/>
          <list-item title="Sobre nosotros" url="/sobre-nosotros" class="my-2"/>
          <list-item title="¿Eres plantador?" url="/soy-plantador"/>
        </v-list-item-group>
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
  import ListItem from '@/components/ListItem';

  export default {
    name: 'AppDrawer',
    
    components: { 
      ListItem,
    },

    props: {
      value: {
        type: Boolean,
        required: true,
      }
    },

    methods: {
    },

    computed:{
      showDrawer:{
        get(){
          return this.value;
        },
        set(newValue){
          this.$emit('input', newValue);
        }
      }
    }
  }
</script>

<style>
  .v-navigation-drawer__border {
    width: 0px!important;
  }
</style>
