export const trees = (state) => {
    return state.trees;
};

export const hasTrees = (state) => {
    return state.trees.length > 0;
};

export const subscription = (state) => {
    return state.subscription;
};

export const subscriptionLevelId = (state, getters) => {

    return getters.hasSubscriptionActive ? state.subscription.levelDetail.id : -1;
};

export const hasSubscription = (state, getters) => {
    return getters.subscription !== null;
};

export const hasSubscriptionActive = (state, getters) => {
    return getters.hasSubscription && getters.subscription.active;
};

export const hasBeenSubscribed = (state, getters) => {
    return getters.hasSubscription;
};

export const hasPayMethod = (state) => {
    return state.payMethod !== null;
}

export const payMethod = (state) => {
    return state.payMethod;
};

export const user = (state, getters, rootState, rootGetters) => {
    return rootGetters['Auth/user']
};

export const isUserLoggedIn = (state, getters, rootState, rootGetters) => {
    return rootGetters['Auth/isUserLoggedIn']
};