<template>
  <div>
    <section id="banner" class="home-banner-container">
      <app-container add-padding name="big">
        <div class="home-banner-wrapper">
          <v-row justify="space-between" align="end" class="home-banner">
            <v-col cols="5">
              <v-img width="auto" contain :height="imagesHeight" src="../assets/img/content/Delivery.gif" />
            </v-col>
            <v-col cols="5">
              <v-img width="auto" :height="imagesHeight" contain src="../assets/img/content/SunSet.svg" />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" class="home-banner-items text-center">
            <div>
              <h1 class="pua-text-xxl">
                Plantar está en la palma de tus manos
              </h1>
              <v-row no-gutters justify="center">
                <p class="pua-text-md text-description mt-3" >
                  Plantemos un Árbol es la primer plataforma en América, en ofrecer un servicio de suscripción para plantar árboles.
                </p>
              </v-row>
              <router-link to="/signup">
                <app-button width="auto">Quiero aportar</app-button>
              </router-link>
            </div>
          </v-row>
        </div>
      </app-container>  
    </section>

    <section id="page-content">
      <app-container add-padding>
        <div id="trees" class="d-flex gap-1 align-center" v-if="isTreesCountSet">
          <div class="pua-text-xxl text-center text--secondary">
            <app-counter :endVal="treesCount.count"/>
            <div class="number-increments" :style="treesClass"/>
            <div> árboles</div>
          </div>
          <div class="pua-text-xl">
            Han sido plantados en nuestro primer año, manteniendo una taza de efectividad del {{ treesCount.effectiveness }}% <span><router-link class="pua-text-sm pua-text-underline text--accent" to="/results">Ver mapa</router-link></span>
          </div>
        </div>
      
        <div id="how-pua-works" class="mt-10">
          <h2 class="pua-text-md pua-text-bolsd text-center">¿Cómo funciona PUA?</h2>
          <v-row no-gutters class="gap-3 mt-5" justify="center">
            <app-card class="pua-card" v-for="(step, index) in steps" :width="cardsWidth" :key="index">
              <div class="d-flex justify-center">
                <div class="pua-text-md green-circle">{{ index + 1}}</div>
              </div>
              <div class="pua-text pua-text-bold my-3">
                {{step.title}}
              </div>
              <div class="pua-text-sm">
                {{ step.description }}
              </div>
            </app-card>
          </v-row>
        </div>

        <div id="gallery" class="mt-10">
          <h3 class="pua-text-xl mb-1">Nuestro trabajo <span><v-icon size="25px" color="primary">mdi-account-hard-hat</v-icon></span></h3>
          <v-row dense >
            <v-col
              v-for="(image, index) in gallery"
              :key="index"
              class="d-flex child-flex"
              cols="6"
              md="4"
            >
              <v-img
                :src="image"
                lazy-src="../assets/img/content/background.jpg"
                alt="Galería"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </div>

        <div id="tools" class="pua-text green-box mt-10">
          Sabemos que como nosotros hay más personas plantando, es por eso que ponemos a disposición nuestras herramientas, para saber que plantar y donde plantar, así como poder gestionar de mejor manera lo que han plantado.
          <router-link to="/soy-plantador" class="pua-text-underline pua-text-sm">ir a registrame</router-link>
        </div>

        <div id= "sponsos" v-if="sponsors.length > 0" class="mt-10 mt-md-15">
          <v-row no-gutters class="gap mb-3 mb-md-5" align-content="center" justify="space-between">
            <div class="d-flex gap-1 align-center">
              <h4 class="pua-text-md">
                Grandes organizaciones que creen en PUA
              </h4>
              <v-icon class="animated-icon">mdi-heart-multiple-outline</v-icon>
            </div>
            <div>
              <router-link to="/soy-organizacion" class="pua-text-underline">Unirme</router-link>
            </div>
          </v-row>
          <small-carrousel>
            <div v-for="(sponsor, index) in sponsors" :key="index">
              <a :href="sponsor.referrer">
                <img width="auto" :height="carrouselHeight" :src="sponsor.image" :alt="sponsor.name" />
              </a>
            </div>
          </small-carrousel>
          <mac-carrousel>
            <div v-for="(sponsor, index) in sponsors" class="mr-4 mr-md-16" :key="index">
              <a :href="sponsor.referrer">
                <v-img :width="carrouselWidth" :height="carrouselHeight" contain :src="sponsor.image" :alt="sponsor.name" />
              </a>
            </div>
          </mac-carrousel>
        </div>
      </app-container>
    </section>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import AppContainer from '@/components/AppContainer.vue';
  import AppButton from '@/components/AppButton.vue';
  import AppCard from '@/components/AppCard.vue';
  import SmallCarrousel from '@/components/SmallCarrousel.vue';
  import AppCounter from '@/components/AppCounter.vue';
  import MacCarrousel from '@/components/MacCarrousel.vue';


  export default {
    name: 'HomePua',

    components: {
      AppContainer,
      AppButton,
      AppCard,
      SmallCarrousel,
      AppCounter,
      MacCarrousel,
    },

    data() {
      return {
        steps: [],

        gallery: [],
      }
    },

    async created() {
      await this.setTreesCount();
      await this.setSponsors();
      this.steps = await this.getSteps();
      this.gallery = await this.getGalleryWork();
    },

    methods: { 
      ...mapActions('Sponsor', ['setSponsors']),
      ...mapActions('Tree', ['setTreesCount', 'getGalleryWork', 'getSteps'])
    },

    computed: { 
      ...mapGetters('Sponsor', ['sponsors']),
      ...mapGetters('Tree', ['treesCount', 'isTreesCountSet']),

      imagesHeight(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '150px';
          case 'sm': return '300px';
          case 'md': return '350px';
          case 'lg': return '350px';
          default: return '350px';
        }
      },

      cardsWidth(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%';
          case 'sm': return '70%';
          default: return '330px';
        }
      },

      carrouselHeight(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '45px';
          case 'sm': return '55px';
          default: return '75px'
        }
      },

      carrouselWidth(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '75px';
          case 'sm': return '100px';
          default: return '125px'
        }
      },

      treesClass(){
        return {
          '--trees-count': this.treesCount.count,
        }
      }
    }
  }
</script>

<style scoped>
.home-banner-container {
  background: rgb(221, 220, 220);
}
.home-banner-wrapper {
  position: relative;
}

.home-banner {
  width: 100%;
  min-height: 450px;
}

.home-banner-items {
  width: 100%;
  position: absolute;
  top: 0px;
}

.text-description{
  max-width: 700px;
}

@media screen and (max-width: 600px) {
  .home-banner {
    min-height: 320px;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .home-banner {
    min-height: 450px;
  }
}
</style>