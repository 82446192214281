import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSocialSharing from 'vue-social-sharing'
import VueMask from 'v-mask'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_KEY
  },
});

Vue.use(VueMask);

Vue.use(VueSocialSharing);

store.dispatch('Auth/recoverUserSession').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');

  if(store.getters['Auth/isUserLoggedIn'] && !store.getters['User/hasBeenSubscribed']){
    router.push({ name: 'userSubscription' })
  }
})
