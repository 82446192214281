import { getSponsors, saveSponsorContact } from "@/services/sponsor";

export const setSponsors = async (context) => {
  if (context.state.sponsors.length <= 0) {
    const response = await getSponsors();
    context.commit("SET_SPONSORS", response);
  }
  return;
};

export const saveSponsorRequest = async (context, data) => {
  return await saveSponsorContact(data);
}