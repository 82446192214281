<template>
    <v-slide-group
      selected-class="bg-success"
      show-arrows
      v-if="UserSystem.appleUser()"
    >
      <slot />
    </v-slide-group>
  </template>
  
  <script>
  import UserSystem from '@/domain/UserSystem.js'; 
  
  export default {
    data() {
      return {
        UserSystem: UserSystem,
      };
    },
  };
  </script>
  